.details-modal {
    display: flex;
    align-items: center;
    justify-content: center;
}

div.details-card {
    width: 50%;
    min-width: 30vw;
    display: flex;
    flex-direction: column;
    border-radius: 1em;
    align-items: center;
}

.details-img {
    height: 150px;
    width: 150px;
    margin-left: auto;
    margin-right: auto;
}

.details-content {
    text-align: center;
}

.details-typing {
    display: flex;
    justify-content: center;
    align-items: center;
}

.evolution-list {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.evolution-img {
    height: 75px;
    width: 75px;
    margin-left: auto;
    margin-right: auto;
}

.MissingNo {
    height: 100px;
    background-image: url(https://www.serebii.net/pokearth/sprites/rb/000.png);
    opacity: 0;
    background-repeat: no-repeat;
    background-position: center; 
}
