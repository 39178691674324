.icon {
    width: 25px;
    height: 25px;
    border-radius: 100%;
    margin: auto;
}

.icon svg {
    height: 60%;
    width: 60%;
    margin: 20%;
}

.MuiTooltip-popper .MuiTooltip-tooltip {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-size: 1.1em;
}

.bug {
    background-color: #92BC2C;
    box-shadow: 0 0 8px #92BC2C;
}

.dark {
    background-color: #595761;
    box-shadow: 0 0 8px #595761;
}

.dragon {
    background-color: #0C69C8;
    box-shadow: 0 0 8px #0C69C8;
}

.electric {
    background-color: #F2D94E;
    box-shadow: 0 0 8px #F2D94E;
}

.fire {
    background-color: #FBA54C;
    box-shadow: 0 0 8px #FBA54C;
}

.fairy {
    background-color: #EE90E6;
    box-shadow: 0 0 8px #EE90E6;
}

.fighting {
    background-color: #D3425F;
    box-shadow: 0 0 8px #D3425F;
}

.flying {
    background-color: #A1BBEC;
    box-shadow: 0 0 8px #A1BBEC;
}

.ghost {
    background-color: #5F6DBC;
    box-shadow: 0 0 8px #5F6DBC;
}

.grass {
    background-color: #5FBD58;
    box-shadow: 0 0 8px #5FBD58;
}

.ground {
    background-color: #DA7C4D;
    box-shadow: 0 0 8px #DA7C4D;
}

.ice {
    background-color: #75D0C1;
    box-shadow: 0 0 8px #75D0C1;
}

.normal {
    background-color: #A0A29F;
    box-shadow: 0 0 8px #A0A29F;
}

.poison {
    background-color: #B763CF;
    box-shadow: 0 0 8px #B763CF;
}

.psychic {
    background-color: #FA8581;
    box-shadow: 0 0 8px #FA8581;
}

.rock {
    background-color: #C9BB8A;
    box-shadow: 0 0 8px #C9BB8A;
}

.steel {
    background-color: #5695A3;
    box-shadow: 0 0 8px #5695A3;
}

.water {
    background-color: #539DDF;
    box-shadow: 0 0 8px #539DDF;
}