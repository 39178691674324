button.showdown-export {
    text-transform: none;
    margin: 0 auto 0.2em;
    width: fit-content;
    border-radius: 2em;
}

.pokemon-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 0.5em;
    margin-bottom: 2em;
    background-color: white;
}

.list-virtualizer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.list-placeholder {
    height: 220px;
}
