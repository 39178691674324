.app-header {
    height: 5em;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    font-size: 20pt;
    font-weight: bold;
    z-index: 5;
}

.header-top {    
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: red;
    color: white;
    border-bottom: 3px solid black;
    width: 100%;
    height: 50%;
}

.header-button {
    position: absolute;
    left: 50%;
    margin-left: -17.5px;
    top: 2em;
    margin-top: -20px;
}

.header-button.background {
    color: white;
}

.header-bottom {    
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: black;
    border-bottom: 1px solid black;
    width: 100%;
    height: 50%;
}
