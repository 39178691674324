.export-modal {
    overflow-y: scroll;
    display: flex;
    align-items: center;
    justify-content: center;
}

.export-card.MuiPaper-root {
    width: 80%;
    border-radius: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.export-title.MuiTypography-root {
    margin: 0.5em;
}

.export-list {
    width: 100%;
}

.export-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.static-row {
    display: flex;
    justify-content: center;
    align-items: center;
}

.export-img {
    width: 100px;
    height: 100px;
    margin-right: 25px;
}

.level-input.MuiOutlinedInput-root {
    width: 5em;
}

.export-actions {
    display: flex;
}

.export-button.MuiButton-root {
    border-radius: 2em;
    margin: 0.5em 0.25em;
    text-transform: none;
}

.export-success {
    color: green;
    margin-bottom: 0.5em;
}