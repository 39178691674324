div.list-filter-container {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 66%;
    padding: 0.5em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 2em;
}

.filters-actions {
    display: flex;
    justify-content: center;
    gap: 2em;
}

button.filters-button {
    text-transform: none;
    font-weight: bold;
    width: fit-content;
    border-radius: 1.5em;
}