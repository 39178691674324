.pokedex-modal {
    display: flex;
    align-items: center;
    justify-content: center;
}

div.pokedex-card {
    width: 50%;
    min-width: 30vw;
    display: flex;
    flex-direction: column;
    border-radius: 1em;
    align-items: center;
}

div.pokedex-content:last-child {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 15px;
}

.pokedex-button-grid {
    width: 90%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

.pokedex-button-container {
    display: flex;
    width: 50%;
    flex-direction: column;
    align-items: center;
}

button.pokedex-button {
    margin: 0.5em 0.5em 0;
    border-radius: 2em;
    text-transform: unset;
}

.pokedex-button.region {
    width: 8em;
}

.pokedex-button.game {
    width: 90%;
    height: 100%;
}