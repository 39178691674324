div.options-box {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 66%;
    padding: 0.5em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 2em;
    margin-top: 9em;
}

.options-row {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0.5em;
}

.options-row > a {
    width: 100%;
}

div.options-control,
button.options-control {
    margin-left: auto;
    margin-right: auto;
}

button.options-control {
    text-transform: none;
    border-radius: 1.5em;
    width: 10em;
}

div.input-field {
    width: 7em;
    text-align: start;
}

label.gen-checkbox {
    margin: 0;
}

.buttons-row {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0.5em;
}

button.options-button {
    margin: 0.5em 0.5em 0;
    border-radius: 2em;
    text-transform: none;
    font-weight: bold;
}

button#generate {
    width: 10em;
    color: black;
    border: 3px solid black;
}

.options-mode-labels {
    display: flex;
    align-items: center;
}
