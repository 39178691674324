div.pokemon-card {
    width: 30%;
    min-width: 30vw;
    margin: 0.5em;
    display: flex;
    flex-direction: column;
    border-radius: 1em;
    align-items: center;
    cursor: pointer;
}

.card-img {
    height: 100px;
    width: 100px;
    margin-left: auto;
    margin-right: auto;
}

button.card-link {
    text-transform: capitalize;
    border-radius: 2em;
    color: white;
    width: 6vw;
}

.card-link.bulbapedia {
    background-color: #0CAA38;
}

.card-link.bulbapedia:hover {
    background-color: #0a8a2e;
}

.card-link.serebii {
    background-color: #8DC76D;
}

.card-link.serebii:hover {
    background-color: #7abe54;
}

.card-link.smogon {
    background-color: #C184FF;
}

.card-link.smogon:hover {
    background-color: #b062ff;
}

.card-link.pokeDB {
    background-color: #727272;
}

.card-link.pokeDB:hover {
    background-color: #616161;
}

.stats-loader {
    overflow: hidden;
}

a {
    color: rgb(0, 0, 238);
}
